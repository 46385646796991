// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/cart/CartContents.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/cart/CartContents.tsx");
  import.meta.hot.lastModified = "1730113364430.6223";
}
// REMIX HMR END

import { Form, Link } from '@remix-run/react';
import { Price } from '~/components/products/Price';
import { useTranslation } from 'react-i18next';
export function CartContents({
  orderLines,
  currencyCode,
  editable = true,
  adjustOrderLine,
  removeItem
}) {
  _s();
  const {
    t
  } = useTranslation();
  const isEditable = editable !== false;
  return <div className="flow-root">
      <ul role="list" className="-my-6 divide-y divide-gray-200">
        {(orderLines ?? []).map(line => <li key={line.id} className="py-6 flex">
            <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
              <img src={line.featuredAsset?.preview + '?preset=thumb'} alt={line.productVariant.name} className="w-full h-full object-center object-cover" />
            </div>

            <div className="ml-4 flex-1 flex flex-col">
              <div>
                <div className="flex justify-between text-base font-medium text-gray-900">
                  <h3>
                    <Link to={`/products/${line.productVariant.product.slug}`}>
                      {line.productVariant.name}
                    </Link>
                  </h3>
                  <p className="ml-4">
                    <Price priceWithTax={line.linePriceWithTax} currencyCode={currencyCode}></Price>
                  </p>
                </div>
              </div>
              <div className="flex-1 flex items-center text-sm">
                {editable ? <Form>
                    <label htmlFor={`quantity-${line.id}`} className="mr-2">
                      {t('common.quantity')}
                    </label>
                    <select disabled={!isEditable} id={`quantity-${line.id}`} name={`quantity-${line.id}`} value={line.quantity} onChange={e => adjustOrderLine && adjustOrderLine(line.id, +e.target.value)} className="max-w-full rounded-md border border-gray-300 py-1.5 text-base leading-5 font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                      <option value={6}>6</option>
                      <option value={7}>7</option>
                      <option value={8}>8</option>
                    </select>
                  </Form> : <div className="text-gray-800">
                    <span className="mr-1">{t('common.quantity')}</span>
                    <span className="font-medium">{line.quantity}</span>
                  </div>}
                <div className="flex-1"></div>
                <div className="flex">
                  {isEditable && <button type="submit" name="removeItem" value={line.id} className="font-medium text-primary-600 hover:text-primary-500" onClick={() => removeItem && removeItem(line.id)}>
                      {t('common.remove')}
                    </button>}
                </div>
              </div>
            </div>
          </li>)}
      </ul>
    </div>;
}
_s(CartContents, "zlIdU9EjM2llFt74AbE2KsUJXyM=", false, function () {
  return [useTranslation];
});
_c = CartContents;
var _c;
$RefreshReg$(_c, "CartContents");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;